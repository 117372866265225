import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import BarChartLegend, {barChartLegend} from "../../components/Legend/BarChartLegend";
import {Dropdown, DropdownItem, FixedColumnCounter} from "../../components";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getPlanSummary} from "../PlanSummary/planSummarySlice";
import {getRefWidth} from "../../ClientManagement/AssetReliance/AssetRelianceUtil";
import {ComparePlansBarchartContainer} from "./ComparePlansBarchartContainer";
import {GoalsTableDisplayForComparePlan} from "./GoalsTableDisplayForComparePlan";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {NetAssetsTableDisplayForComparePlan} from "./NetAssetsDisplayTableForComparePlans";
import {ExcessAssetsDisplayTableForComparePlans} from "./ExcessAssetsDisplayTableForComparePlans";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {AccordionTableHeaderOnly} from "../../components/AccordionTable/AccordionTableHeaderOnly";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {COLOR_ASSETS_ACCOUNTS, COLOR_EXCESS_ASSETS_ACCENT, COLOR_GOALS} from "../../constants/colors";
import "../../styles/pages/_asset-reliance-barchart.scss";
import {emptyInvestorGroup, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import Highcharts from "highcharts";
import {calculateCNWInEstateTotalValue} from "../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {CreatePlanSummaryNetWorthOverTimeGraphOptions} from "../PlanSummary/PlanSummaryNetWorthOverTimeGraphUtils";
import {AssetsSummary, emptyAssetsSummary} from "../../Assets/models/Assets";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import NetWorthOverTimeRow from "./NetWorthOverTimeRow";
import {selectClientAssets, setClientAssets} from "../../Assets/clientAssetsSlice";
import ComparePlansRiskDonut from "./ComparePlansRiskDonut";
import ReserveTargetLengthRow from "./ReserveTargetLengthRow";
import {emptyEstateSummary, EstateSummary, EstateType} from "../../WealthTransfer/models/api";
import {wealthTransferApiClient} from "../../WealthTransfer/WealthTransferApiClient";
import EstimatedEstateTaxRow from "./EstimatedEstateTaxRow";

const ComparePlansContent: React.FC = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const profile = useAppSelector(selectProfile)!;
    const profileIdForProposal = useAppSelector(selectProfile)?.profileIdForProposal
    const {proposals} = useProfileAndProposals(id);
    const [barChartColWidth, setBarChartColWidth] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [profilePlanSummaryResponse, setProfilePlanSummaryResponse] = useState<PlanSummaryResponse>();
    const [proposalPlanSummaryResponse, setProposalPlanSummaryResponse] = useState<PlanSummaryResponse | null>(null);
    const [selectedProposalId, setSelectedProposalId] = useState<string | null>(null);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [investorGroupForProposal, setInvestorGroupForProposal] = useState<InvestorGroupType>(emptyInvestorGroup);
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);
    const [clientAssetsForProposal, setClientAssetsForProposal] = useState<AssetsSummary | undefined>(emptyAssetsSummary);
    const [portfolioReserveTargetLengthForProfile, setPortfolioReserveTargetLengthForProfile] = useState<number | undefined>(undefined);
    const [portfolioReserveTargetLengthForProposal, setPortfolioReserveTargetLengthForProposal] = useState<number | undefined>(undefined);
    const [estateSummary, setEstateSummary] = useState<EstateSummary>(emptyEstateSummary);
    const [estateSummaryForProposal, setEstateSummaryForProposal] = useState<EstateSummary>(emptyEstateSummary);

    const chartLegend = <div>
        <div className="compare-plans-sub-header">Excess Assets</div>
        <div className="bar-chart-legend-container">
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.NET_ASSETS} label={"ASSETS"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.GOALS} label={"GOALS"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.EXCESS_ASSETS} label={"EXCESS ASSETS"}/>
            </div>
        </div>
    </div>;

    useEffect(() => {
        let isMounted = true;
        const idToFetch = profileIdForProposal ?? profile.id;
        (async () => {
            const response = await dispatch(getPlanSummary(idToFetch));
            if (isMounted && response.payload) {
                Promise.all([
                    clientManagementApiClient.getInvestorGroup(idToFetch),
                    assetsApiClient.getAssetsSummary(idToFetch),
                    wealthTransferApiClient.getEstateSummary(idToFetch, EstateType.CURRENT),
                    clientManagementApiClient.getProfile(idToFetch)
                ]).then(([newInvestorGroup,
                             assetSummaryResponse,
                             estateSummaryResponse,
                             profileResponse]) => {
                    setInvestorGroup(newInvestorGroup);
                    dispatch(setClientAssets(assetSummaryResponse));
                    setEstateSummary(estateSummaryResponse);
                    setPortfolioReserveTargetLengthForProfile(profileResponse.portfolioReserveTargetLength);
                    setIsLoading(false);
                });
                setProfilePlanSummaryResponse(response.payload as PlanSummaryResponse);

            }
        })();
        return () => {
            isMounted = false;
        };
    }, [id, profile.id, profileIdForProposal]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            if (selectedProposalId != null) {
                setIsLoading(true);
                const response = await dispatch(getPlanSummary(selectedProposalId));
                if (isMounted && response.payload) {
                    Promise.all([
                        clientManagementApiClient.getInvestorGroup(selectedProposalId),
                        assetsApiClient.getAssetsSummary(selectedProposalId),
                        wealthTransferApiClient.getEstateSummary(selectedProposalId, EstateType.CURRENT),
                        clientManagementApiClient.getProfile(selectedProposalId)
                    ]).then(([newInvestorGroup,
                                 assetSummaryResponse,
                                 estateSummaryResponse,
                                 proposalResponse]) => {
                        setInvestorGroupForProposal(newInvestorGroup);
                        setClientAssetsForProposal(assetSummaryResponse);
                        setEstateSummaryForProposal(estateSummaryResponse);
                        setPortfolioReserveTargetLengthForProposal(proposalResponse.portfolioReserveTargetLength);
                        setIsLoading(false);
                    });
                    setProposalPlanSummaryResponse(response.payload as PlanSummaryResponse);
                }
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [selectedProposalId]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const netAssets = (profilePlanSummaryResponse!.totalNetValue ?? 0) + (profilePlanSummaryResponse!.totalAssetPurchaseValue ?? 0);
    const totalGoals = profilePlanSummaryResponse!.goalsTotalPresentValue ?? 0;
    const excessAssets = profilePlanSummaryResponse!.excessAssets;
    const proposalNetAssets = (proposalPlanSummaryResponse?.totalNetValue ?? 0) + (proposalPlanSummaryResponse?.totalAssetPurchaseValue ?? 0);
    const proposalTotalGoals = proposalPlanSummaryResponse?.goalsTotalPresentValue ?? 0;
    const proposalExcessAssets = proposalPlanSummaryResponse?.excessAssets ?? 0;

    const goalsTableDisplay = new GoalsTableDisplayForComparePlan(
        new FixedColumnCounter(2),
        totalGoals,
        proposalTotalGoals
    );

    const netAssetsTableDisplay = new NetAssetsTableDisplayForComparePlan(
        new FixedColumnCounter(2),
        netAssets,
        proposalNetAssets
    );

    const excessAssetsTableDisplay = new ExcessAssetsDisplayTableForComparePlans(
        new FixedColumnCounter(2),
        excessAssets,
        proposalExcessAssets
    );

    const totalTaxLiabilitiesForAllAccounts = clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const netWealthAtCurrentAgeForProfile = calculateCNWInEstateTotalValue(clientAssets!) + totalTaxLiabilitiesForAllAccounts;
    const graphOptionsForProfile: Highcharts.Options = CreatePlanSummaryNetWorthOverTimeGraphOptions({
        investorGroup: investorGroup,
        netWealthAtCurrentAge: netWealthAtCurrentAgeForProfile,
        futureValueOfExcessAssetsAtAge: profilePlanSummaryResponse!.futureValueOfExcessAssetsByAge,
    });
    const totalTaxLiabilitiesForAllAccountsForProposal = clientAssetsForProposal!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const netWealthAtCurrentAgeForProposal = calculateCNWInEstateTotalValue(clientAssetsForProposal!) + totalTaxLiabilitiesForAllAccountsForProposal;
    const graphOptionsForProposal: Highcharts.Options = CreatePlanSummaryNetWorthOverTimeGraphOptions({
        investorGroup: investorGroupForProposal,
        netWealthAtCurrentAge: netWealthAtCurrentAgeForProposal,
        futureValueOfExcessAssetsAtAge: proposalPlanSummaryResponse?.futureValueOfExcessAssetsByAge ?? [],
    });

    return (
        <main className="compare-plans-content main-container">
            <Row style={{marginBottom: 25}}>
                <Col width={TITLE_CELL_WIDTH}>
                    {chartLegend}
                </Col>
                <span className="divider"></span>
                <Col md={CHART_TITLE_CELL_WIDTH}
                     ref={(col: Col & HTMLDivElement | null) => {
                         const width = getRefWidth(col);
                         setBarChartColWidth(width);
                     }}
                >
                    <div className="compare-plans-sub-header sub-title">
                        <span>Profile (Current)</span>
                    </div>
                </Col>
                <span className="divider"></span>
                <Col md={CHART_TITLE_CELL_WIDTH}>
                    <Dropdown
                        className="compare-plans-dropdown"
                        label="Select a plan"
                        inlineLabel
                        panelHeight="auto"
                        panelWidth="300px"
                        size='medium'
                        disabled={proposals.length === 0}
                        value={selectedProposalId}
                        onChange={(e) => setSelectedProposalId(e.value)}>
                        {proposals.map(proposal => <DropdownItem key={proposal.id} value={proposal.id}
                                                                 itemText={proposal.displayName}
                                                                 style={{paddingLeft: '35px'}}
                        />)}
                    </Dropdown>
                </Col>
            </Row>
            <ComparePlansBarchartContainer netAssets={netAssets}
                                           barChartColWidth={barChartColWidth}
                                           totalStacks={proposalPlanSummaryResponse != null ? 2 : 1}
                                           totalGoals={totalGoals}
                                           excessAssets={excessAssets}
                                           proposalNetAssets={proposalNetAssets}
                                           proposalTotalGoals={proposalTotalGoals}
                                           proposalExcessAssets={proposalExcessAssets}
            />
            <div className="margintop-xxxl">
                <AccordionTableHeaderOnly accordionTableId={"compare-plans-assets"}
                                          collapsedAccentColor={COLOR_ASSETS_ACCOUNTS}
                                          ariaLabel={"Compare-Plans-Assets"}
                                          titleCellWidth={TITLE_CELL_WIDTH}
                                          tableDisplayData={netAssetsTableDisplay}
                                          valueColumnSize={4}

                />

            </div>
            <div>
                <AccordionTableHeaderOnly accordionTableId={"compare-plans-goals"}
                                          collapsedAccentColor={COLOR_GOALS}
                                          ariaLabel={"Compare-Plans-Goals"}
                                          titleCellWidth={TITLE_CELL_WIDTH}
                                          tableDisplayData={goalsTableDisplay}
                                          valueColumnSize={4}

                />

            </div>
            <div>
                <AccordionTableHeaderOnly accordionTableId={"compare-plans-excess-assets"}
                                          collapsedAccentColor={COLOR_EXCESS_ASSETS_ACCENT}
                                          ariaLabel={"compare-plans-excess-assets-row"}
                                          titleCellWidth={TITLE_CELL_WIDTH}
                                          tableDisplayData={excessAssetsTableDisplay}
                                          valueColumnSize={4}
                />
            </div>
            <NetWorthOverTimeRow
                profilePlanSummaryResponse={profilePlanSummaryResponse!}
                proposalPlanSummaryResponse={proposalPlanSummaryResponse!}
                graphOptionsForProfile={graphOptionsForProfile}
                graphOptionsForProposal={graphOptionsForProposal}
            />
            <hr style={{marginLeft: -15}}/>
            <ComparePlansRiskDonut
                profilePlanSummaryResponse={profilePlanSummaryResponse!}
                proposalPlanSummaryResponse={proposalPlanSummaryResponse!}
            />
            <hr style={{marginLeft: -15}}/>
            <ReserveTargetLengthRow
                profileReserveTargetLength={portfolioReserveTargetLengthForProfile}
                proposalReserveTargetLength={portfolioReserveTargetLengthForProposal}
                proposalPlanSummaryResponse={proposalPlanSummaryResponse!}
            />
            <hr style={{marginLeft: -15}}/>
            <EstimatedEstateTaxRow
                profileEstimatedEstateTax={estateSummary.estimatedEstateTax}
                proposalPlanSummaryResponse={proposalPlanSummaryResponse!}
                proposalEstimatedEstateTax={estateSummaryForProposal.estimatedEstateTax}
            />
        </main>
    )
}

export default ComparePlansContent;
export const TITLE_CELL_WIDTH = 374;
export const CHART_CELL_WIDTH = 370;
export const CHART_TITLE_CELL_WIDTH = 4;