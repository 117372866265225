import React, { useEffect, useState } from 'react'
import SuccessorTableRow from './SuccessorTableRow'
import { FamilyMembers, Member, RevocableTrust, Trustee, TrusteeType } from "../EstateFlowchartTypes";
import { SetStateAction } from 'react';
import { SubSectionHeader } from 'src/Assets/Ownership/components/SubSectionHeader';
import { isUUID } from './validations';
import { Droppable, Draggable, DragDropContext, DropResult } from 'react-beautiful-dnd';

type SuccessorTableProps = {
    dropdownHeight: number,
    basicInformation: RevocableTrust,
    familyMembersResponse: FamilyMembers,
    updateBasicInformation: React.Dispatch<SetStateAction<RevocableTrust>>
}

type SuccessorRow = {
    order: number,
    name: string,
    successors: Member[],
    selectedSuccessors: string[],
}

function SuccessorTable({
    dropdownHeight,
    basicInformation,
    familyMembersResponse,
    updateBasicInformation
}: SuccessorTableProps): JSX.Element {

    const [successorRows, setSuccessorRows] = useState([] as SuccessorRow[])
    const [successors, setSuccessors] = useState([] as Member[])

    const parseTrustees = (s: SuccessorRow[]): Trustee[] => {
        let members = [] as Trustee[];
        s.forEach((row, order) => {
            for (const element of row.successors) {
                const member: Trustee = { revTrustId: "", memberType: TrusteeType.Successor, familyMember: true, customName: "" };
                member.memberType = TrusteeType.Successor;
                member.revTrustId = basicInformation.revTrustId;
                member.memberOrder = order;
                if (element.selected) {
                    if (element.hasOwnProperty("icon")) {
                        member.customName = element.label;
                        member.familyMember = false;
                        if (isUUID(element.value)) {
                            member.memberId = element.value;
                        }
                        members.push(member);
                    } else {
                        if (row.selectedSuccessors.includes(element.value)) {
                            member.familyMember = true;
                            member.memberId = element.value;
                            members.push(member);
                        }
                    }
                } else {
                    if (row.selectedSuccessors.includes(element.value)) {
                        member.familyMember = true;
                        member.memberId = element.value;
                        members.push(member);
                    }
                }
            }
        })
        return members;
    }

    const onChangeSuccessor = (selectedOptions: any, index: number) => {
        const initialRow = successorRows.find(item => item.order.toString() === index.toString());
        if (initialRow !== undefined) {
            initialRow.selectedSuccessors = selectedOptions.map((option: any) => option.value);
        }
        setSuccessorRows((prevItems: any[]) => prevItems.map(item =>
            item.order.toString() === index.toString() ? { ...item, ...initialRow } : item
        ));

        const members: Trustee[] = parseTrustees(successorRows);
        
        updateBasicInformation({ ...basicInformation, successors: members });
    }

    const onDeleteSuccessor = (order: number) => {
        const updatedRows = successorRows.filter(row => row.order.toString() !== order.toString());
        setSuccessorRows(updatedRows);
    }

    const buildInitialSuccessorRows = (familyMembers: any[]) => {
        if (basicInformation.successors && basicInformation.successors.length > 0) {
            const successorsFromState = [...basicInformation.successors].sort((a, b) => a.memberOrder && b.memberOrder ? a.memberOrder - b.memberOrder : 0);
            const successorsByOrder: any = {};
            successorsFromState?.forEach((item) => {
                if (item.memberOrder !== undefined) {
                    if (!successorsByOrder[item.memberOrder]) {
                        successorsByOrder[item.memberOrder] = [];
                    }
                    successorsByOrder[item.memberOrder].push(item);
                }
            })
            for (const i in successorsByOrder) {
                let ntItem = { label: "Northern Trust", selected: false, value: "", icon: "" };
                let row: SuccessorRow = {
                    name: "successor",
                    selectedSuccessors: [],
                    successors: [...familyMembers, ntItem] as any[],
                    order: Number(i)
                };
                row.selectedSuccessors = successorsByOrder[i].map((member: Trustee) => member.memberId);
                successorsByOrder[i].forEach((member: Trustee) => {
                    if (!member.familyMember) {
                        let item = { label: "", selected: false, value: "", icon: "" };
                        item.selected = true;
                        item.label = member.customName ? member.customName : "";
                        item.value = member.memberId ? member.memberId : "";
                        row.successors.push(item);
                    }
                })
                setSuccessorRows(prevArray => [...prevArray, row]);
            }
        }
    }

    const onAddSuccessor = () => {
        let ntItem = { label: "Northern Trust", selected: false, value: "", icon: "" };
        let newSuccessor = { name: "successor", selectedSuccessors: [], successors: [...successors, ntItem], order: successorRows.length };
        setSuccessorRows([...successorRows, newSuccessor])
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(successorRows);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        items.forEach((item, index) => item.order = index);
        setSuccessorRows(items);
        const members: Trustee[] = parseTrustees(items);
        updateBasicInformation({ ...basicInformation, successors: members });
    }

    useEffect(() => {
        buildInitialSuccessorRows(familyMembersResponse.familyMember);
        setSuccessors(prevArray => [...prevArray, ...familyMembersResponse.familyMember]);
    }, []);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <SubSectionHeader
                onAddButtonClick={onAddSuccessor}
                title="Successors"
                buttonText="ADD SUCCESSOR TRUSTEE"
                subtitle="Add successors and reorder using the drag icon."
            />
            <div className="successor-table-header-grid">
                <span id="successor-order">ORDER</span> <span id="successor-name">SUCCESSOR NAME</span>
            </div>
            <Droppable droppableId="successor-table">
                {(provided, snapshot) => (
                    <div className="successor-input-grid" ref={provided.innerRef} >
                        {successorRows.map((row, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index} >
                                {(p, s) => (
                                    <SuccessorTableRow row={row} index={index} dropdownHeight={dropdownHeight} onChangeSuccessor={onChangeSuccessor} onDeleteSuccessor={onDeleteSuccessor}  provided={p} />
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default SuccessorTable