import {Col, Row} from "react-grid-system";
import React from "react";
import {CHART_TITLE_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";

interface ReserveTargetLengthRowProps {
    profileReserveTargetLength: number | undefined;
    proposalReserveTargetLength: number | undefined;
    proposalPlanSummaryResponse: PlanSummaryResponse;

}

const ReserveTargetLengthRow: React.FC<ReserveTargetLengthRowProps> = ({
                                                                           profileReserveTargetLength,
                                                                           proposalReserveTargetLength,
                                                                           proposalPlanSummaryResponse
                                                                       }) => {
    return <Row style={{marginTop: 15}}>
        <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
            <div className="compare-plans-sub-header" style={{marginTop:15}}>Portfolio Reserve Target Length</div>
        </Col>
        <span className="divider"></span>
        <Col md={CHART_TITLE_CELL_WIDTH}>
            <div className="plan-summary-card">
                <div style={{textAlign: "center", marginTop:15}}>
                    <b>{profileReserveTargetLength ?? 0} years</b>
                </div>
            </div>
        </Col>
        <span className="divider"></span>
        <Col md={CHART_TITLE_CELL_WIDTH}>
            {proposalPlanSummaryResponse != null &&
                <>
                    <div className="plan-summary-card">
                        <div style={{textAlign: "center", marginTop:15}}>
                            <b>{proposalReserveTargetLength ?? 0} years</b>
                        </div>
                    </div>
                </>
            }
        </Col>
    </Row>
}

export default ReserveTargetLengthRow;