import React from "react";
import {Col, Row} from "react-grid-system";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {CHART_TITLE_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";
import {formatNumberRoundedToWholeNumber} from "../../utils/format";


interface ComparePlansRiskDonutProps {
    profilePlanSummaryResponse: PlanSummaryResponse;
    proposalPlanSummaryResponse: PlanSummaryResponse;
}

const ComparePlansRiskDonut: React.FC<ComparePlansRiskDonutProps> = ({
                                                                         profilePlanSummaryResponse,
                                                                         proposalPlanSummaryResponse
                                                                     }) => {

    return (
        <Row style={{marginTop: 15}}>
            <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                <div className="compare-plans-sub-header">Proposed Asset Allocation</div>
                <div className='subhead'>Total Portfolio</div>
            </Col>
            <span className="divider"></span>
            <Col md={CHART_TITLE_CELL_WIDTH}>
                <Row>
                    <Col>
                        <div style={{paddingLeft:100, marginTop:25}}>
                            <RiskDonut
                                donutSize={'xsm'}
                                data={profilePlanSummaryResponse!.proposedAllocation}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{marginLeft:-20}} className={"risk-assets-and-risk-control"}>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                     <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'RA'}/>
                                    <span className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(profilePlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent)}%`}</b></span>

                                </div>

                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                label={'RCA'}/>
                                   <span className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(profilePlanSummaryResponse!.proposedAllocation.totalRiskControlAssetsPercent)}%`}</b></span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            <span className="divider"></span>
            <Col md={CHART_TITLE_CELL_WIDTH}>
                {proposalPlanSummaryResponse != null &&
                    <Row>
                        <Col>
                            <div style={{paddingLeft:100, marginTop:25}}>
                                <RiskDonut
                                    donutSize={'xsm'}
                                    data={proposalPlanSummaryResponse!.proposedAllocation}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div style={{marginLeft:-20}} className={"risk-assets-and-risk-control"}>
                                <div className={"risk"}>
                                    <div className={"risk-legend-label"}>
                                        <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'RA'}/>
                                        <span className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(proposalPlanSummaryResponse!.proposedAllocation.totalRiskAssetsPercent)}%`}</b></span>

                                    </div>

                                </div>
                                <div className={"risk"}>
                                    <div className={"risk-legend-label"}>
                                        <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                    label={'RCA'}/>
                                        <span className={"risk-percentage"}><b>{` ${formatNumberRoundedToWholeNumber(proposalPlanSummaryResponse!.proposedAllocation.totalRiskControlAssetsPercent)}%`}</b></span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                }

            </Col>
        </Row>
    );
}

export default ComparePlansRiskDonut;