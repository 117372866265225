import {Col, Row} from "react-grid-system";
import React from "react";
import {CHART_TITLE_CELL_WIDTH, TITLE_CELL_WIDTH} from "./ComparePlansContent";
import {formatCurrency} from "../../utils/format";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";

interface EstimatedEstateTaxRowProps {
    profileEstimatedEstateTax: number;
    proposalPlanSummaryResponse: PlanSummaryResponse;
    proposalEstimatedEstateTax: number;
}

const EstimatedEstateTaxRow: React.FC<EstimatedEstateTaxRowProps> = ({
                                                                         profileEstimatedEstateTax,
                                                                         proposalEstimatedEstateTax,
                                                                         proposalPlanSummaryResponse
                                                                     }) => {
    return <Row style={{marginTop: 15}}>
        <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
            <div className="compare-plans-sub-header" style={{marginTop: 15}}>Estimated Estate Tax</div>
        </Col>
        <span className="divider"></span>

        <Col md={CHART_TITLE_CELL_WIDTH}>
            <div className="plan-summary-card">
                <div style={{textAlign: "center", marginTop: 15}}>
                    <b>{formatCurrency(profileEstimatedEstateTax)}</b>
                </div>
            </div>
        </Col>
        <span className="divider"></span>
        <Col md={CHART_TITLE_CELL_WIDTH}>
            {proposalPlanSummaryResponse != null &&
                <>
                    <div className="plan-summary-card">
                        <div style={{textAlign: "center", marginTop: 15}}>
                            <b>{formatCurrency(proposalEstimatedEstateTax ?? 0)}</b>
                        </div>
                    </div>
                </>
            }
        </Col>
    </Row>
}

export default EstimatedEstateTaxRow;