import React, { SetStateAction, useEffect } from 'react'
import DropdownMultiselect from './DropdownMultiselect'
import { Member, RevocableTrust } from '../EstateFlowchartTypes'
import { DraggableProvided } from 'react-beautiful-dnd'

type SuccessorTableRowProps = {
    row: SuccessorRow,
    index: number,
    dropdownHeight: number,
    provided: DraggableProvided,
    onChangeSuccessor: (options: String[], index: number) => void,
    onDeleteSuccessor: (order: number) => void,
}

type SuccessorRow = {
    order: number,
    name: string,
    successors: Member[],
    selectedSuccessors: string[],
}

function SuccessorTableRow({
    row,
    index,
    dropdownHeight,
    provided,
    onChangeSuccessor,
    onDeleteSuccessor
}: SuccessorTableRowProps): JSX.Element {
    return (
        <div className="layout-data-entry-form__field trust-details__successor-row" data-testid={`successor-trustees-dropdown-${index}`} key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <i className="dds-icons icon drag__icon" aria-hidden="true">drag_vertical</i>
            <DropdownMultiselect
                className={'dds-ms'}
                alignRight={false}
                creatable
                disabled={false}
                dropUp={false}
                dynamicDropAlign={false}
                dynamicDropDirection={false}
                hideSelectAll
                id="DDMS-creatable"
                label=""
                onChange={(options: String[]) => { onChangeSuccessor(options, index) }}
                open={false}
                options={row.successors}
                panelHeight={dropdownHeight}
                panelWidth="100%"
                placeholder=""
                required={false}
                searchable
                selectAllLabel=""
                selected={row.selectedSuccessors}
                selectedDisplayTextAfter=""
                selectedDisplayTextBefore=""
                showTotalSelected={false}
                showClearAllButton={row.selectedSuccessors.length > 1}
                showFullSelected={true}
            />

            <i className="dds-icons icon paddingright-md paddingleft-md delete-successor-trustee" onClick={() => onDeleteSuccessor(row.order)} aria-hidden="true">delete</i>
        </div>
    )
}

export default SuccessorTableRow