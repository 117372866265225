import React from "react";
import {Col, Row} from "react-grid-system";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {formatCurrency} from "../../utils/format";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {TITLE_CELL_WIDTH, CHART_TITLE_CELL_WIDTH} from "./ComparePlansContent";

interface NetWorthOverTimeRowProps {
    profilePlanSummaryResponse: PlanSummaryResponse;
    proposalPlanSummaryResponse: PlanSummaryResponse;
    graphOptionsForProfile: Highcharts.Options;
    graphOptionsForProposal: Highcharts.Options;
}

const NetWorthOverTimeRow: React.FC<NetWorthOverTimeRowProps> = ({
                                                                     profilePlanSummaryResponse,
                                                                     proposalPlanSummaryResponse,
                                                                     graphOptionsForProfile,
                                                                     graphOptionsForProposal
                                                                 }) => {
    return (
        <Row style={{marginTop: 15}}>
            <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                <div className="compare-plans-sub-header">Net Worth Over Time</div>
                <div className='subhead'>Expected excess at the end of the planning period</div>
            </Col>
            <span className="divider"></span>
            <Col md={CHART_TITLE_CELL_WIDTH}>
                <div className="plan-summary-card">
                    {profilePlanSummaryResponse.excessAssets > 0 ?
                        <>
                            <div style={{textAlign: "center"}}>
                                {formatCurrency(profilePlanSummaryResponse.futureValueOfExcessAssets)}
                            </div>
                            <HighchartsReact highcharts={Highcharts} options={graphOptionsForProfile}/>
                        </>
                        : <>
                            <div className="asset_insufficiency_empty_state"> Net Worth Over Time cannot be displayed
                                when there is an asset shortfall.
                            </div>
                        </>
                    }
                </div>
            </Col>
            <span className="divider"></span>
            <Col md={CHART_TITLE_CELL_WIDTH}>
                <div className="plan-summary-card">
                    {proposalPlanSummaryResponse != null &&
                        <>
                            {proposalPlanSummaryResponse.excessAssets > 0 ?
                                <>
                                    <div style={{textAlign: "center"}}>
                                        {formatCurrency(proposalPlanSummaryResponse.futureValueOfExcessAssets)}
                                    </div>
                                    <HighchartsReact highcharts={Highcharts} options={graphOptionsForProposal}/>
                                </>
                                : <>
                                    <div className="asset_insufficiency_empty_state"> Net Worth Over Time cannot be
                                        displayed
                                        when there is an asset shortfall.
                                    </div>
                                </>
                            }
                        </>
                    }

                </div>
            </Col>
        </Row>
    );
}

export default NetWorthOverTimeRow;